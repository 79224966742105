<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container"></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="owner">Owner</vs-th>
          <vs-th sort-key="reportedBy">Reported By</vs-th>
          <!-- <vs-th sort-key="message">Message</vs-th> -->
          <vs-th sort-key="createdAt">Reported At</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td :data="data[indextr].owner">{{ data[indextr].owner.email }}</vs-td> -->
            <div v-if="data[indextr].car.sellerType==='dealership'">
              <vs-td v-if="data[indextr].car.ownerDealer" :data="data[indextr].car.ownerDealer.contactInformation">{{ data[indextr].car.ownerDealer.contactInformation.email }}</vs-td>
              <vs-td v-else>N/A</vs-td>
            </div>
            <div v-if="data[indextr].car.sellerType==='private'">
              <vs-td v-if="data[indextr].car.ownerUser" :data="data[indextr].car.ownerUser.email">{{ data[indextr].car.ownerUser.email }}</vs-td>
              <vs-td v-else>N/A</vs-td>
            </div>
            <vs-td :data="data[indextr].reportedBy">
              {{ (data[indextr] && data[indextr].reportedBy && data[indextr].reportedBy.email) || '' }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].message">{{ data[indextr].message }}</vs-td> -->
            <vs-td
              :data="data[indextr].createdAt"
            >{{ moment(data[indextr].createdAt).format('DD/MM/YYYY') }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
                <vx-tooltip text="Vehicle Detail" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewCarDetails(data[indextr].car._id)"
                    icon="icon-truck"
                    color="success"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="View Report" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-file-text"
                    color="primary"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span
        class="mr-2"
      >{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }} - {{ totalDocs - currentPage * dataTableParams.limit > 0 ? currentPage * dataTableParams.limit : totalDocs }} of {{ totalDocs }}</span>
      <vs-pagination :total="totalPage" v-model="dataTableParams.page"></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: this.$store.getters.reportPage,
        limit: this.$store.getters.reportPageLimit,
      },
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    ...mapActions("report", ["fetchReports"]),
    ...mapActions(["updateReportPage", "initToFirstPage", "updateReportPageLimit"]),
    moment(date) {
      return moment(date);
    },
    getReportsList() {
      let self = this;
      this.fetchReports(self.dataTableParams).then((res) => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getReportsList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getReportsList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getReportsList();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "ReportDetail", params: { id: id } });
    },
    viewCarDetails(id) {
      this.$router.push({ name: "VehicleDetails", params: { id: id } });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateReportPage(newVal);
        this.dataTableParams.page = newVal;
        this.getReportsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.updateReportPageLimit(newlimit);
        // this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getReportsList();
      }
    },
  },
  created() {
    this.initToFirstPage({pageType: "report"});
    this.getReportsList();
  },
};
</script>
